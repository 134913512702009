import { FC, HTMLAttributes, useEffect, useLayoutEffect, useRef, useState } from 'react'
import Shard from './Shard'
import Occlusion from './Occlussion'
import { ArrowIcon, Noise } from '../../assets'
import iOS from '../../utils/ios'

const ShardBackground: FC<{ mobile?: boolean } & HTMLAttributes<HTMLDivElement>> = ({ mobile, ...props }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
  const [scroll, setScroll] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  const [fillers, setFillers] = useState<any>()
  const wrapperRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (mobile) return
    if (!ref.current) return
    const onMove = (e: MouseEvent) => {
      const rect = ref.current?.getClientRects()[0]
      if (!rect) return
      setMousePosition({ x: e.screenX - (rect.left + rect.width / 2), y: e.screenY - (rect.top + rect.height / 2) })
    }
    window.addEventListener('mousemove', onMove)
    const rect = ref.current?.getClientRects()[0]
    if (rect) {
      setFillers(
        Array(25)
          .fill(0)
          .map(() => ({ left: Math.random() * (document.body.clientWidth - rect.left), top: Math.random() * rect.height, z: -Math.random() * 20 - 10, size: Math.random() * 20 + 2 }))
      )
    }

    return () => {
      window.removeEventListener('mousemove', onMove)
    }
  }, [ref, mobile])

  useLayoutEffect(() => {
    if (!mobile || mobile) return
    if (!ref.current) return
    const onMove = (e: TouchEvent) => {
      const rect = ref.current?.getClientRects()[0]
      const touch = e.touches[0]
      if (!rect || !touch) return
      setMousePosition({ x: touch.screenX - (rect.left + rect.width / 2), y: touch.screenY - (rect.top + rect.height / 2) })
    }
    window.addEventListener('touchmove', onMove)
    const rect = ref.current?.getClientRects()[0]
    if (rect) {
      setFillers(
        Array(25)
          .fill(0)
          .map(() => ({ left: Math.random() * (document.body.clientWidth - rect.left), top: Math.random() * rect.height, z: -Math.random() * 20 - 10, size: Math.random() * 20 + 2 }))
      )
    }

    return () => {
      window.removeEventListener('touchmove', onMove)
    }
  }, [ref, mobile])

  useLayoutEffect(() => {
    if (!ref.current) return
    const el = ref.current
    const onScroll = () => {
      const rect = el.getBoundingClientRect()
      if (!rect) return
      setScroll(rect.top)
    }
    el.addEventListener('scroll', onScroll)
    return () => {
      el.removeEventListener('scroll', onScroll)
    }
  }, [ref])

  const translationY = mobile ? 0 /* 10 * (mousePosition.y + scroll * 100) */ : mousePosition.y + scroll * 2
  const translationX = mobile ? 0 /* mousePosition.x * 10 */ : mousePosition.x

  // if (mobile) return null

  return (
    <div
      ref={ref}
      id="shards-wrapper"
      className={mobile ? 'flex grow w-full overflow-visible -mb-20 absolute top-0' : 'flex relative overflow-hidden h-full'}
      style={{ transformStyle: 'flat' /*, perspective: "5000px"*/, height: mobile ? window.innerHeight * 0.59 : undefined }}
    >
      <div className="w-full h-full relative">
        <Shard right={330} top={20} z={15} translationX={translationX} translationY={translationY} rotation={-10} size={150} />
        <Shard right={320} top={10} z={-50} translationX={translationX} translationY={translationY} rotation={10} size={60} />
        <Shard right={-30} top={310} z={12} translationX={translationX} translationY={translationY} rotation={255} size={280} />
        <Shard right={70} top={-20} z={24} translationX={translationX} translationY={translationY} rotation={0} size={300} />
        <Shard right={65} top={220} z={-30} translationX={translationX} translationY={translationY} rotation={70} size={150} />
        <Shard right={260} top={245} z={24} translationX={translationX} translationY={translationY} rotation={-60} size={100} />
        <Shard right={135} top={360} z={-15} translationX={translationX} translationY={translationY} rotation={7} size={80} />
        <Shard right={240} top={375} z={-12} translationX={translationX} translationY={translationY} rotation={-15} size={70} />
        <Shard right={210} top={315} z={-8} translationX={translationX} translationY={translationY} rotation={10} size={50} dark brightness={90} />

        <Shard right={380} top={150} z={-61} translationX={translationX} translationY={translationY} rotation={90} size={60} dark />
        <Shard right={440} top={190} z={-62} translationX={translationX} translationY={translationY} rotation={-10} size={30} dark />
        <Shard right={380} top={240} z={-30} translationX={translationX} translationY={translationY} rotation={110} size={70} dark />
        <Shard right={440} top={20} z={-62} translationX={translationX} translationY={translationY} rotation={90} size={60} blur={5} brightness={90} dark />

        <Shard right={370} top={300} z={-61} translationX={translationX} translationY={translationY} rotation={90} size={28} blur={5} dark />
        <Shard right={350} top={330} z={-20} translationX={translationX} translationY={translationY} rotation={-10} size={30} blur={1} dark />
        <Shard right={300} top={360} z={-30} translationX={translationX} translationY={translationY} rotation={110} size={27} blur={4} dark />
        <Shard right={215} top={255} z={-50} translationX={translationX} translationY={translationY} rotation={110} size={40} blur={10} dark />

        {!mobile && (
          <>
            <Shard left={150} bottom={70} z={-12} translationX={translationX} translationY={translationY} rotation={110} size={50} dark />
            <Shard left={70} bottom={0} z={-12} translationX={translationX} translationY={translationY} rotation={90} size={75} />
            <Shard left={40} bottom={100} z={8} translationX={translationX} translationY={translationY} rotation={60} size={125} />

            <Shard left={30} bottom={25} z={-50} translationX={translationX} translationY={translationY} rotation={70} size={65} blur={4} dark />
            <Shard left={140} bottom={-5} z={-20} translationX={translationX} translationY={translationY} rotation={190} size={40} blur={4} dark />
          </>
        )}

        <Shard right={-275} top={-250} z={100} translationX={translationX} translationY={translationY} rotation={313} size={550} blur={10} brightness={90} />

        {fillers?.map((filler: any, i: number) => (
          <Shard key={i} left={filler.left} top={filler.top} z={filler.z} translationX={translationX} translationY={translationY} rotation={0} size={filler.size} blur={5} brightness={90} dark />
        ))}

        <Occlusion right={330} top={310} z={-40} translationX={translationX} translationY={translationY} rotation={0} size={200} />
        <Occlusion right={440} top={20} z={-50} translationX={translationX} translationY={translationY} rotation={0} size={300} dark />
        <Occlusion right={40} top={20} z={-2} translationX={translationX} translationY={translationY} rotation={0} size={250} dark />
        <Occlusion right={10} top={700} z={-20} translationX={translationX} translationY={translationY} rotation={0} size={300} />
        <Occlusion right={450} top={400} z={-10} translationX={translationX} translationY={translationY} rotation={0} size={250} />
        <Occlusion left={20} top={200} z={4} translationX={translationX} translationY={translationY} rotation={10} size={40} dark />
        <div style={{ background: `url(${Noise}` }} className="w-full h-full absolute inset-0 hidden" />
      </div>
      {!mobile && (
        <div
          id="our-projects"
          className="absolute left-1/2 -translate-x-1/2 flex flex-col bottom-5 items-center cursor-pointer space-y-1 group hover:translate-y-1 transition-all"
          onClick={(e) => {
            wrapperRef.current?.classList.add('!top-0', 'opacity-100')
            e.currentTarget.style.opacity = '0'
          }}
        >
          <p>Our projects</p>
          <ArrowIcon className="group-hover:left-1 transition-all rotate-90 group-hover:translate-y-2" />
        </div>
      )}

      {!mobile && (
        <div ref={wrapperRef} className="absolute left-0 right-0 opacity-0 pt-50 h-full overflow-y-auto transition-all duration-[1s] z-[10000] backdrop-blur-xl top-[100vh]" {...props}>
          {props.children}
        </div>
      )}
    </div>
  )
}

export default ShardBackground
