import { FC, useEffect } from 'react'
import { ArrowTopIcon } from '../assets'
import { ApexMockup, AvatarMobileMockup, AvatarMockup, InscriptsMockup, YieldfarmMockup } from '../assets/mockups'
import { BoxSvg, CallIllustration, ComputerSvg, DiamondSvg, DollarSvg, EnvelopeSvg, FingerprintSvg, InfinitySvg, LightningSvg, QuotesSvg, ReferIllustration, ResizeSvg } from '../assets/svgs'
import FAQQuestion from '../components/ShardBackground/FAQQuestion'

const SubscriptionPage: FC = () => {
  useEffect(() => {
    document.body.style.backgroundColor = 'white'
  }, [])

  const refer = () => {
    window.open('https://forms.gle/sx19F6qcC2SxCRqK8', '_blank')
  }

  const book = () => {
    window.open('https://calendly.com/tomorrowdev', '_blank')
  }

  const start = () => {
    console.log('start')
  }

  const yearly = () => {
    window.open('https://buy.stripe.com/dR64hf27A0V97HqcMO', '_blank')
  }

  const quarterly = () => {
    window.open('https://buy.stripe.com/00geVTaE6gU7e5ObIJ', '_blank')
  }

  const monthly = () => {
    window.open('https://buy.stripe.com/4gw4hf27A7jxgdW4gg', '_blank')
  }

  return (
    <div id="top" className="w-full relative">
      <div className="bg-black flex animate-navbar items-center p-2 rounded-full fixed w-max z-[1000] left-1/2 -translate-x-1/2 bottom-0 border border-slate-200 mb-0 text-white font-bold text-base">
        <a href="#top" className="p-6 rounded-full bg-[rgba(255,255,255,0.12)] hover:bg-[rgba(255,255,255,0.24)] transition-all w-min h-min">
          <ArrowTopIcon />
        </a>
        <a href="#benefits" className="px-8 cursor-pointer border-b border-b-transparent py-4 hover:border-b-white transition-all">
          Benefits
        </a>
        <a href="#scope" className="px-8 cursor-pointer border-b border-b-transparent py-4 hover:border-b-white transition-all">
          Scope of Work
        </a>
        <a href="#pricing" className="px-8 cursor-pointer border-b border-b-transparent py-4 hover:border-b-white transition-all">
          Pricing
        </a>
        <a href="#faq" className="px-8 cursor-pointer border-b border-b-transparent py-4 hover:border-b-white transition-all mr-4">
          FAQs
        </a>
        {/*<a href="#top" className="p-6 rounded-full bg-[rgba(255,255,255,0.12)] hover:bg-[rgba(255,255,255,0.24)] transition-all w-min h-min">
          Login
  </a>*/}
      </div>
      <div className="w-full flex flex-col bg-noise2-purple">
        <div id="top" className="w-full flex justify-center py-12 px-24 text-black font-bold">
          <span className="font-jakarta text-2xl mobile:text-4xl">Tomorrow</span>
        </div>
        <div className="w-full flex flex-col items-center p-24 mobile:px-12">
          <span className="text-black font-bold text-[64px] leading-[140%] mobile:text-8xl text-center">
            World Class Software Development
            <br />
            With Unbeatable Speed & Price
          </span>
          <span className="text-black font-normal text-2xl mobile:text-5xl mobile:leading-[180%] font-normal text-center mt-6 mb-24">Development Subscription to Scale Your Business.</span>
          <a href="#pricing" className="rounded-xl hover:-translate-y-1 hover:drop-shadow-xl transition-all bg-black py-6 mobile:py-8 mobile:text-4xl px-16 text-white w-max leading-none font-bold text-2xl">
            See Plans
          </a>
        </div>
        <div className="pb-24 grid grid-cols-4 w-full mobile:grid-cols-2">
          <div className="grow h-[464px] mobile:aspect-square mobile:h-auto" style={{ background: `url(${AvatarMockup})`, backgroundOrigin: 'center center', backgroundSize: 'cover' }} />
          <div className="grow h-[464px] mobile:aspect-square mobile:h-auto" style={{ background: `url(${ApexMockup})`, backgroundOrigin: 'center center', backgroundSize: 'cover' }} />
          <div className="grow h-[464px] mobile:aspect-square mobile:h-auto" style={{ background: `url(${InscriptsMockup})`, backgroundOrigin: 'center center', backgroundSize: 'cover' }} />
          <div className="grow h-[464px] mobile:aspect-square mobile:h-auto" style={{ background: `url(${YieldfarmMockup})`, backgroundOrigin: 'center center', backgroundSize: 'cover' }} />
        </div>
        <div className="w-full overflow-x-hidden relative flex items-center bg-black text-white text-2xl font-semibold font-jakarta marquee">
          <div className="mobile:w-max mobile:min-w-max w-full flex items-center py-16 mobile:gap-8 pl-24 mobile:pl-8 text-white text-2xl font-semibold justify-between">
            <span className="min-w-max">Booksmatch</span>
            <span className="min-w-max">Avatar</span>
            <span className="min-w-max">Intelify</span>
            <span className="min-w-max">Apex Management</span>
            <span className="min-w-max">YieldFarm</span>
            <span className="min-w-max">LegalTLDR</span>
          </div>
          <div className="mobile:w-max mobile:min-w-max w-full flex items-center py-16 pl-24 mobile:gap-8 pl-24 mobile:pl-8 text-white text-2xl font-semibold justify-between">
            <span className="min-w-max">Booksmatch</span>
            <span className="min-w-max">Avatar</span>
            <span className="min-w-max">Intelify</span>
            <span className="min-w-max">Apex Management</span>
            <span className="min-w-max">YieldFarm</span>
            <span className="min-w-max">LegalTLDR</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full bg-noise2-white">
        <div className="w-full flex flex-col items-center p-24 mobile:py-48">
          <span className="text-black font-bold text-[48px] leading-[140%] mobile:leading-[110%] text-center">We Didn’t Reinvent the Wheel, Just Software Development</span>
          <span className="text-black font-normal text-2xl font-normal mobile:text-4xl mobile:leading-[180%] text-center mt-6">Say goodbye to inefficient software development</span>
        </div>
        <div className="px-24 pb-24 grid grid-cols-3 gap-12 mobile:grid-cols-1">
          <div className="flex flex-col items-center grow">
            <EnvelopeSvg />
            <span className="text-xl font-normal text-center mt-6 mobile:text-4xl mobile:leading-[180%]">Add as many of your development requests as possible.</span>
          </div>
          <div className="flex flex-col items-center grow">
            <ComputerSvg />
            <span className="text-xl font-normal text-center mt-6 mobile:text-4xl mobile:leading-[180%]">Get all of your tasks coded in just a few days on average.</span>
          </div>
          <div className="flex flex-col items-center grow">
            <BoxSvg />
            <span className="text-xl font-normal text-center mt-6 mobile:text-4xl mobile:leading-[180%]">No surprises here! Pay the same fixed price each month.</span>
          </div>
        </div>
        <div className="p-24 mobile:px-12 mobile:pt-12 mobile:pb-48">
          <div className="bg-noise2-purple flex flex-col p-24 items-center mobile:px-12">
            <QuotesSvg />
            <span className="text-[40px] mobile:text-6xl leading-[140%] font-bold mt-24 text-center">I can’t keep up with giving tasks to these guys.</span>
            <span className="text-2xl text-center font-normal mobile:text-4xl mobile:leading-[180%] font-jakarta mt-6">Stefano Sciuto - Apex Management</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full bg-noise2-gray p-24 mobile:py-48 mobile:px-12">
        <span className="text-black font-bold text-[48px] leading-[140%] mobile:leading-[110%] mobile:text-[42px] text-center">It’s “You’ll Never Go Back” Better</span>
        <span className="text-black font-normal text-2xl mobile:text-4xl mobile:leading-[180%] font-normal text-center mt-6 mb-24">
          Tomorrow replaces unreliable developers and expensive employees for one flat monthly fee, with product development delivered so fast that it will blow your mind.
        </span>
        <div className="grid grid-cols-2 gap-24 mobile:grid-cols-1">
          <div className="flex bg-slate-50 flex-col p-12">
            <QuotesSvg />
            <span className="text-[26px] font-bold leading-[160%] mt-[4.5rem]">
              Tomorrow team was able to learn and deliver a project made with a completely new technology in a matter of days... astounding!
            </span>
            <span className="text-2xl font-normal mt-6 mobile:text-4xl">Joshua Anderson - Inscripts</span>
          </div>
          <div className="flex flex-col gap-12">
            <div className="flex flex-col space-y-1.5 mobile:space-y-6">
              <span className="text-2xl font-bold mobile:text-4xl">Totally Async</span>
              <span className="text-xl leading-[160%] mobile:text-3xl">
                Don't like wasting time on useless meetings? We don't either; that is why our developers focus only development. All communication is handled by project manager.
              </span>
            </div>
            <div className="flex flex-col space-y-1.5 mobile:space-y-6">
              <span className="text-2xl font-bold mobile:text-4xl">Manage With Trello</span>
              <span className="text-xl leading-[160%] mobile:text-3xl">Manage your design board using Trello. View active, queued and completed tasks with ease. </span>
            </div>
            <div className="flex flex-col space-y-1.5 mobile:space-y-6">
              <span className="text-2xl font-bold mobile:text-4xl">Invite Unlimited Team Members </span>
              <span className="text-xl leading-[160%] mobile:text-3xl">Invite your entire team, so anyone can submit requests and track their progress. </span>
            </div>
          </div>
        </div>
      </div>
      <div id="benefits" className="flex flex-col w-full bg-noise2-white">
        <div className="w-full flex flex-col items-center p-24 mobile:py-48 mobile:px-12">
          <span className="text-black font-bold text-[48px] leading-[140%] mobile:leading-[110%] mobile:text-[42px] text-center">Membership Benefits</span>
          <span className="text-black font-normal text-2xl font-normal mobile:text-4xl mobile:leading-[180%] text-center mt-6 mb-24">Perks so good you'll never need to go anywhere else for your design. Seriously.</span>
          <a href="#pricing" className="rounded-xl hover:-translate-y-1 hover:drop-shadow-xl transition-all bg-black py-6 mobile:py-8 mobile:text-4xl px-16 text-white w-max leading-none font-bold text-2xl">
            See Plans
          </a>
        </div>
        <div className="px-24 pb-24 grid grid-cols-3 gap-12 mobile:grid-cols-1 mobile:gap-24">
          <div className="flex flex-col items-center">
            <InfinitySvg />
            <span className="text-2xl font-bold mt-8 mb-1.5 text-center mobile:text-4xl mobile:mb-6">Development Board</span>
            <span className="text-xl font-normal text-center mobile:text-3xl">Add as many of your development requests as possible.</span>
          </div>
          <div className="flex flex-col items-center">
            <LightningSvg />
            <span className="text-2xl font-bold mt-8 mb-1.5 text-center mobile:text-4xl mobile:mb-6">Lightning Fast Delivery</span>
            <span className="text-xl font-normal text-center mobile:text-3xl">Get all of your tasks coded in just a few days on average.</span>
          </div>
          <div className="flex flex-col items-center">
            <DollarSvg />
            <span className="text-2xl font-bold mt-8 mb-1.5 text-center mobile:text-4xl mobile:mb-6">Fixed Monthly Rate</span>
            <span className="text-xl font-normal text-center mobile:text-3xl">No surprises here! Pay the same fixed price each month.</span>
          </div>
          <div className="flex flex-col items-center">
            <DiamondSvg />
            <span className="text-2xl font-bold mt-8 mb-1.5 text-center mobile:text-4xl mobile:mb-6">Top Notch Quality</span>
            <span className="text-xl font-normal text-center mobile:text-3xl">Incredible and insane development quality at your fingertips.</span>
          </div>
          <div className="flex flex-col items-center">
            <ResizeSvg />
            <span className="text-2xl font-bold mt-8 mb-1.5 text-center mobile:text-4xl mobile:mb-6">Flexible & Scalable</span>
            <span className="text-xl font-normal text-center mobile:text-3xl">Scale up or down as needed, and pause or cancel at anytime.</span>
          </div>
          <div className="flex flex-col items-center">
            <FingerprintSvg />
            <span className="text-2xl font-bold mt-8 mb-1.5 text-center mobile:text-4xl mobile:mb-6">Unique & All Yours</span>
            <span className="text-xl font-normal text-center mobile:text-3xl">Your development task are custom coded for you and are 100% yours.</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full bg-noise2-gray">
        <div className="w-full flex flex-col items-center p-24 mobile:py-48 mobile:px-12">
          <span className="text-black font-bold text-[48px] leading-[140%] mobile:leading-[110%] mobile:text-[42px] text-center">Recent Works</span>
          <span className="text-black font-normal text-2xl font-normal mobile:text-4xl mobile:leading-[180%] text-center mt-6 mb-24">Web 3, Web 2, desktop, mobile, web, simple, complex. We can do it ALL.</span>
          <button className="rounded-xl hover:-translate-y-1 hover:drop-shadow-xl transition-all bg-black py-6 mobile:py-16 mobile:text-4xl px-16 text-white w-max leading-none font-bold text-2xl">View Recent Work</button>
          <div className="grid grid-cols-2 w-full gap-12 mt-24 mobile:grid-cols-1">
            <div className="!aspect-square w-full" style={{ background: `url(${AvatarMobileMockup})`, backgroundOrigin: 'center center', backgroundSize: 'cover' }} />
            <div className="!aspect-square w-full" style={{ background: `url(${ApexMockup})`, backgroundOrigin: 'center center', backgroundSize: 'cover' }} />
            <div className="!aspect-square w-full" style={{ background: `url(${InscriptsMockup})`, backgroundOrigin: 'center center', backgroundSize: 'cover' }} />
            <div className="!aspect-square w-full" style={{ background: `url(${YieldfarmMockup})`, backgroundOrigin: 'center center', backgroundSize: 'cover' }} />
          </div>
        </div>
      </div>
      <div id="scope" className="flex flex-col w-full bg-noise2-white p-24 mobile:py-48 mobile:px-12">
        <span className="text-black font-bold text-[48px] leading-[140%] mobile:leading-[110%] mobile:text-[42px] text-center">All-in-One Service</span>
        <div className="grid grid-cols-2 gap-8 w-full p-16 mobile:p-12 text-xl mobile:text-3xl font-bold border-[6px] border-purple-100 mt-24 mobile:mt-48 mobile:grid-cols-1">
          <span className="grow pb-8 border-b-[6px] border-b-purple-100">Web</span>
          <span className="grow pb-8 border-b-[6px] border-b-purple-100">Backend Development</span>
          <span className="grow pb-8 border-b-[6px] border-b-purple-100">Android</span>
          <span className="grow pb-8 border-b-[6px] border-b-purple-100">DevOps</span>
          <span className="grow pb-8 border-b-[6px] border-b-purple-100">iOs</span>
          <span className="grow pb-8 border-b-[6px] border-b-purple-100">React</span>
          <span className="grow pb-8 border-b-[6px] border-b-purple-100">Product Management</span>
          <span className="grow pb-8 border-b-[6px] border-b-purple-100">Java</span>
          <span className="grow pb-8 border-b-[6px] border-b-purple-100">QA</span>
          <span className="grow pb-8 border-b-[6px] border-b-purple-100">Typescript</span>
          <span className="grow pb-8 border-b-[6px] border-b-purple-100">Frontend Development</span>
          <span className="grow pb-8 border-b-[6px] border-b-purple-100">Next.js</span>
        </div>
        <div className="grid grid-cols-2 mt-24 gap-24 mobile:grid-cols-1">
          <div className="flex flex-col space-y-3">
            <span className="text-4xl mb-9 font-bold">Team Stack</span>
            <div className="p-4 space-x-4 flex items-center bg-slate-50 rounded-xl border border-slate-200">
              <div className="w-12 h-12 rounded-full bg-pink-400" />
              <div className="h-full w-px bg-slate-200" />
              <div className="space-y-1.5 flex flex-col">
                <span className="text-xs mobile:text-xl font-semibold text-pink-400">Project Manager</span>
                <span className="text-xl mobile:text-3xl font-semibold">Andrew Taylor</span>
              </div>
            </div>
            <div className="p-4 space-x-4 flex items-center bg-slate-50 rounded-xl border border-slate-200">
              <div className="w-12 h-12 rounded-full bg-yellow-400" />
              <div className="h-full w-px bg-slate-200" />
              <div className="space-y-1.5 flex flex-col">
                <span className="text-xs mobile:text-xl font-semibold text-yellow-400">Quality Assurance Analyst</span>
                <span className="text-xl mobile:text-3xl font-semibold">Matthew Davis</span>
              </div>
            </div>
            <div className="p-4 space-x-4 flex items-center bg-slate-50 rounded-xl border border-slate-200">
              <div className="w-12 h-12 rounded-full bg-emerald-400" />
              <div className="h-full w-px bg-slate-200" />
              <div className="space-y-1.5 flex flex-col">
                <span className="text-xs mobile:text-xl font-semibold text-emerald-400">Lead Software Engineer</span>
                <span className="text-xl mobile:text-3xl font-semibold">William Johnson</span>
              </div>
            </div>
            <div className="p-4 space-x-4 flex items-center bg-slate-50 rounded-xl border border-slate-200">
              <div className="w-12 h-12 rounded-full bg-indigo-400" />
              <div className="h-full w-px bg-slate-200" />
              <div className="space-y-1.5 flex flex-col">
                <span className="text-xs mobile:text-xl font-semibold text-indigo-400">Frontend Developer</span>
                <span className="text-xl mobile:text-3xl font-semibold">Christopher Wilson</span>
              </div>
            </div>
            <div className="p-4 space-x-4 flex items-center bg-slate-50 rounded-xl border border-slate-200">
              <div className="w-12 h-12 rounded-full bg-red-400" />
              <div className="h-full w-px bg-slate-200" />
              <div className="space-y-1.5 flex flex-col">
                <span className="text-xs mobile:text-xl font-semibold text-red-400">Backend Developer</span>
                <span className="text-xl mobile:text-3xl font-semibold">Daniel Brown</span>
              </div>
            </div>
            <div className="p-4 space-x-4 flex items-center bg-slate-50 rounded-xl border border-slate-200">
              <div className="w-12 h-12 rounded-full bg-amber-800" />
              <div className="h-full w-px bg-slate-200" />
              <div className="space-y-1.5 flex flex-col">
                <span className="text-xs mobile:text-xl font-semibold text-amber-800">UI/UX Designer</span>
                <span className="text-xl mobile:text-3xl font-semibold">Michael Smith</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-start pt-24 items-start">
            <span className="text-4xl font-bold mobile:text-6xl">Our Subscription Replaces up to 6 People</span>
            <span className="text-xl mt-6 mb-16 mobile:text-3xl mobile:mt-16 mobile:leading-[180%]">
              Our company is full of developers specialized in different niches, helping us maximize speed and quality by assigning specialized experts to each part of your project.
            </span>
            <button onClick={book} className="rounded-xl hover:-translate-y-1 hover:drop-shadow-xl transition-all bg-black py-6 mobile:py-16 mobile:text-4xl px-16 text-white w-max leading-none font-bold text-2xl">
              Schedule a Meeting
            </button>
          </div>
        </div>
      </div>
      <div id="pricing" className="bg-noise2-purple p-24 mobile:py-48 mobile:px-12 flex flex-col items-center">
        <span className="text-black font-bold text-[48px] leading-[140%] leading-[140%] mobile:leading-[110%] text-center">Membership Levels</span>
        <span className="text-black font-normal text-2xl font-normal mobile:text-4xl mobile:mt-16 mobile:leading-[180%] text-center mt-6 mb-24">Choose one of our plans mentioned below. A plan that's right for you.</span>
        <div className="grid grid-cols-4 gap-0.5 mobile:grid-cols-1 mobile:gap-12">
          <div className="flex flex-col space-y-0.5 grow">
            <div className="flex flex-col space-y-8 mobile:space-y-16 bg-white p-8 grow">
              <div className="flex flex-col space-y-1.5 mobile:space-y-6">
                <span className="text-xl mobile:text-6xl font-bold">Monthly</span>
                <span className="text-sm mobile:text-4xl mobile:leading-[180%] font-jakarta">No minimum commitment. Pause or cancel anytime.</span>
              </div>
              <div className="flex flex-col space-y-1.5 grow">
                <span className="text-xl mobile:text-6xl font-bold">$9,995/m</span>
                <span className="text-sm mobile:text-4xl mobile:leading-[180%] text-slate-500">Pause or Cancel Anytime</span>
              </div>
              <button onClick={monthly} className="rounded-xl mobile:self-center hover:-translate-y-1 hover:drop-shadow-xl transition-all bg-black py-6 mobile:py-16 mobile:text-4xl px-16 text-white w-max leading-none font-bold text-2xl">
                Get Started
              </button>
              <button
                onClick={book}
                className="border-dashed font-jakarta border-b-2 self-center transition-all border-b-black pb-3 hover:px-8 hover:rounded-2xl hover:drop-shadow-xl mobile:text-4xl mobile:pb-6 rounded-none w-max leading-none font-bold text-2xl"
              >
                Book a Call
              </button>
            </div>
            <div className="p-8 flex flex-col space-y-3 bg-white">
              <span className="text-base mobile:text-4xl font-bold">What’s Included</span>
              <ul className="text-sm mobile:text-4xl list-disc pl-4 mobile:pl-8 !leading-[180%] font-jakarta">
                <li>One request at a time for each development role</li>
                <li>Team of 6 people available</li>
                <li>Unlimited team members</li>
                <li>Unlimited projects</li>
                <li>Easy credit-card payments</li>
                <li>Pause or cancel anytime</li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col space-y-0.5 grow">
            <div className="flex flex-col space-y-8 mobile:space-y-16 bg-white p-8 grow">
              <div className="flex flex-col space-y-1.5 mobile:space-y-6">
                <span className="text-xl mobile:text-6xl font-bold">Quarterly</span>
                <span className="text-sm mobile:text-4xl mobile:leading-[180%] font-jakarta">Save $500 per month..</span>
              </div>
              <div className="flex flex-col space-y-1.5 grow">
                <span className="text-xl mobile:text-6xl font-bold">$9,495/m</span>
                <span className="text-sm mobile:text-4xl mobile:leading-[180%] text-slate-500 font-jakarta">Paid Quarterly</span>
              </div>
              <button onClick={quarterly} className="rounded-xl mobile:self-center hover:-translate-y-1 hover:drop-shadow-xl transition-all bg-black py-6 mobile:py-16 mobile:text-4xl px-16 text-white w-max leading-none font-bold text-2xl">
                Get Started
              </button>
              <button
                onClick={book}
                className="border-dashed font-jakarta border-b-2 self-center transition-all border-b-black pb-3 hover:px-8 hover:rounded-2xl hover:drop-shadow-xl mobile:text-4xl mobile:pb-6 rounded-none w-max leading-none font-bold text-2xl"
              >
                Book a Call
              </button>
            </div>
            <div className="p-8 flex flex-col space-y-3 bg-white">
              <span className="text-base mobile:text-4xl font-bold">What’s Included</span>
              <ul className="text-sm mobile:text-4xl list-disc pl-4 mobile:pl-8 !leading-[180%] font-jakarta">
                <li>One request at a time for each development role</li>
                <li>Team of 6 people available</li>
                <li>Dedicated Project Manager</li>
                <li>Monthly Reports</li>
                <li>Work Optimization</li>
                <li>Easy credit-card payments</li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col space-y-0.5 grow">
            <div className="flex flex-col space-y-8 mobile:space-y-16 bg-white p-8 grow">
              <div className="flex flex-col space-y-1.5 mobile:space-y-6">
                <span className="text-xl mobile:text-6xl font-bold">Yearly</span>
                <span className="text-sm mobile:text-4xl mobile:leading-[180%] font-jakarta">Save $1,000 per month.</span>
              </div>
              <div className="flex flex-col space-y-1.5 grow">
                <span className="text-xl mobile:text-6xl font-bold">$8,995/m</span>
                <span className="text-sm mobile:text-4xl mobile:leading-[180%] text-slate-500 font-jakarta">Paid Annually</span>
              </div>
              <button onClick={yearly} className="rounded-xl mobile:self-center hover:-translate-y-1 hover:drop-shadow-xl transition-all bg-black py-6 mobile:py-16 mobile:text-4xl px-16 text-white w-max leading-none font-bold text-2xl">
                Get Started
              </button>
              <button
                onClick={book}
                className="border-dashed font-jakarta border-b-2 self-center transition-all border-b-black pb-3 hover:px-8 hover:rounded-2xl hover:drop-shadow-xl mobile:text-4xl mobile:pb-6 rounded-none w-max leading-none font-bold text-2xl"
              >
                Book a Call
              </button>
            </div>
            <div className="p-8 flex flex-col space-y-3 bg-white">
              <span className="text-base mobile:text-4xl font-bold">What’s Included</span>
              <ul className="text-sm mobile:text-4xl list-disc pl-4 mobile:pl-8 !leading-[180%] font-jakarta">
                <li>One request at a time for each development role</li>
                <li>Team of 6 people available</li>
                <li>Dedicated Project Manager</li>
                <li>Monthly Reports</li>
                <li>Work Optimization</li>
                <li>Easy credit-card payments</li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col grow p-8 bg-white mobile:bg-transparent mobile:border-2 mobile:px-0 mobile:border-black items-center">
            <CallIllustration />
            <div className="flex flex-col space-y-1.5 mt-8 mobile:px-12">
              <span className="text-2xl mobile:text-4xl mobile:text-center font-bold">Book a Call</span>
              <span className="font-jakarta text-sm mobile:text-3xl mobile:text-center leading-[180%]">Learn more about how Tomorrow works and how it can help you.</span>
            </div>
            <span className="py-8 !text-left mobile:!text-center mobile:text-4xl w-full text-sm font-bold cursor-pointer hover:pl-2 transition-all" onClick={book}>
              Book a Call
            </span>
            <div className="h-0.5 w-full bg-black mb-8" />
            <ReferIllustration />
            <div className="flex flex-col space-y-1.5 mt-8 mobile:px-12">
              <span className="text-2xl mobile:text-4xl mobile:text-center font-bold">Refer & Earn</span>
              <span className="font-jakarta text-sm mobile:text-3xl mobile:text-center leading-[180%]">Earn 5% monthly recurring commissions for each referral.</span>
            </div>
            <span className="py-8 !text-left mobile:!text-center mobile:text-4xl w-full text-sm font-bold cursor-pointer hover:pl-2 transition-all" onClick={refer}>
              Join Now
            </span>
          </div>
          <div className="bg-black py-12 px-24 col-span-4 flex flex-col items-center mobile:col-span-1">
            <span className="px-4 pt-2 pb-2.5 rounded-full bg-purple-100 mb-5 text-sm mobile:text-3xl font-bold">ADD-ON</span>
            <span className="text-5xl font-bold text-white mobile:mb-6">UI/UX Design</span>
            <span className="text-xl font-jakarta mb-5 text-white mobile:text-3xl mobile:text-center">Last missing piece to full-cycle development. Requires a development subscription.</span>
            <span className="text-5xl font-bold text-white">$999/m</span>
          </div>
        </div>
      </div>
      <div id="faq" className="flex flex-col w-full p-24 mobile:py-48 mobile:px-12 items-center bg-noise2-white">
        <span className="text-5xl font-bold mb-24">FAQs</span>
        <div className="flex flex-col w-full">
          <FAQQuestion
            question="Is there a limit to how many requests I can have?"
            answer="Once subscribed, you're able to add as many development requests to your queue as you'd like for each member of the team, and they will be delivered one by one. Each member will have his own tasks queue."
          />
          <FAQQuestion
            question="How fast will I receive my development work?"
            answer="Depending on the complexity of the request, the project manager will break it down into smaller tasks. On average, simple requests are completed in just two or three days. Generally, the speed of every member of the team will be on par with that of a full-time employee."
          />
          <FAQQuestion question="Who are the developers?" answer="Product Manager and Tech lead are the founders of the agency. Rest of the developers are mid to senior level developers." />
          <FAQQuestion
            question="How does the pause feature work?"
            answer="We understand you may not have enough design work to fill up entire month. Perhaps you only have one or two design requests at the moment. That's where pausing your subscription comes in handy.<br/>Billing cycles are based on a 31 day period. Let's say you sign up and use the service for 21 days, and then decide to pause your subscription. This means that the billing cycle will be paused and you'll have 10 days of service remaining to be used anytime in the future."
          />
          <FAQQuestion
            question="How do I request development work?"
            answer="Tomorrow offers a ton of flexibility in how you development requests using Trello. Some common ways clients request designs is directly via Trello, sharing Google docs or wireframes, or even recording a brief Loom video (for those who prefer not to write their briefs out). Basically, if it can be linked to or shared in Trello, it's fair game.<br/>Additionally, you will be able to chat with the dedicated project manager for your company. He will then proceed to break down your tasks into a digestible language of developers."
          />
          <FAQQuestion question="What if I don't like the deliverable?" answer="No worries! We'll continue to revise our work until you're 100% satisfied." />
          <FAQQuestion
            question="What if I only have a few small requests?"
            answer="That's fine. You can pause your subscription when finished and return when you have additional development work needs. There's no need to let the remainder of your subscription go to waste."
          />
          <FAQQuestion
            question="Are there any refunds if I don't like the service?"
            answer="We stand behind what we do, and that is why we give 100% money back if we do not meet product deliverables. However, due to the nature of the work, if we deliver what we promised but you change your mind, we can not issue any refunds."
          />
        </div>
      </div>
        <div className="w-full overflow-x-hidden relative flex items-center bg-black text-white text-2xl font-semibold font-jakarta marquee">
          <div className="mobile:w-max mobile:min-w-max w-full flex items-center py-16 mobile:gap-8 pl-24 mobile:pl-8 text-white text-2xl font-semibold justify-between">
            <span className="min-w-max">Booksmatch</span>
            <span className="min-w-max">Avatar</span>
            <span className="min-w-max">Intelify</span>
            <span className="min-w-max">Apex Management</span>
            <span className="min-w-max">YieldFarm</span>
            <span className="min-w-max">LegalTLDR</span>
          </div>
          <div className="mobile:w-max mobile:min-w-max w-full flex items-center py-16 pl-24 mobile:gap-8 pl-24 mobile:pl-8 text-white text-2xl font-semibold justify-between">
            <span className="min-w-max">Booksmatch</span>
            <span className="min-w-max">Avatar</span>
            <span className="min-w-max">Intelify</span>
            <span className="min-w-max">Apex Management</span>
            <span className="min-w-max">YieldFarm</span>
            <span className="min-w-max">LegalTLDR</span>
          </div>
        </div>
      <div className="px-24 pb-24 mobile:px-12 pt-20 flex flex-col bg-black items-center relative">
        <div className="px-24 mobile:px-12 pb-[120px] flex flex-col bg-black items-center">
          <span className="text-5xl font-bold text-white mb-6 px-24 mobile:px-0 text-center">See if Tomorrow is Right for You</span>
          <span className="text-xl leading-[180%] font-jakarta text-white mb-16 mobile:text-3xl mobile:leading-[180%] px-24 mobile:px-0 mobile:pt-8 text-center">
            Get a guided tour through Tomorrow, and find out how you and your team can change the way you approach development, forever.
          </span>
          <div className="relative w-full h-[130px]">
            <a
              href="#pricing"
              className="p-16 absolute text-center hover:border-b-8 hover:border-l-8 w-full hover:border-r-transparent hover:border-t-transparent border hover:border-slate-600 rounded-xl border-white text-white w-full text-3xl font-bold"
            >
              Get Started
            </a>
          </div>
        </div>
        <div className="flex space-x-6 mobile:space-x-0 w-full items-start justify-between mobile:flex-col-reverse mobile:gap-16 mobile:pb-24">
          <div className="flex gap-6 items-end mobile:flex-col mobile:items-start">
            <span className="font-jakarta text-4xl font-bold text-white">Tomorrow</span>
            <span className="font-jakarta text-base text-white mobile:text-2xl">Tomorrow is headquartered in Bratislava, Slovakia.</span>
          </div>
          {/*<div className="grid grid-cols-2 text-white mobile:grid-cols-1 mobile:gap-8 mobile:text-2xl">
            <div className="flex flex-col space-y-8">
              <a href="#">Latest Projects</a>
              <a href="#pricing">Pricing</a>
              <a href="#">Contact</a>
            </div>
            <div className="flex flex-col space-y-8">
              <a href="#">Client Login</a>
              <a href="#">Get Started</a>
              <a href="#">Terms & Conditions</a>
              <a href="#">Privacy Policy</a>
            </div>
  </div>*/}
        </div>
      </div>
    </div>
  )
}

export default SubscriptionPage
