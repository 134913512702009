import { FC } from 'react'
import { ApexLogo, ArrowIcon, InscriptsLogo, ConfidentialLogo, Logo, YieldfarmLogo, ShieldIcon, InfoIcon } from '../../assets'
import ShardBackground from '../../components/ShardBackground'

const MainMobile: FC = () => {
  return (
    <div className="w-full overflow-y-scroll overflow-x-hidden relative hidden h-full mobile:flex">
      <div className="hidden mobile:block relative w-screen h-full">
        <div className="flex flex-col justify-end items-center h-full relative h-full">
          <ShardBackground mobile />
          <div className="px-8 z-[10000000] w-full flex flex-col justify-end">
            <img src={Logo} className="h-[5rem] w-auto absolute top-[30px] left-9" />
            <h1 className="text-white text-left text-6xl w-full">We are a full-cycle development agency</h1>
            <p className="text-white text-left description !text-3xl !mt-10 w-full overflow-visible">
              Our unique combination of location, experience and skills allows us to offer you with the best services on the market.
            </p>
            <p className="text-white text-left description !my-7 !text-3xl !font-medium w-full">Don’t believe it? Find out for yourself right now!</p>
            <div className="flex justify-between !mt-4 items-center flex-col space-y-5 w-full">
              <button className="button-purple h-20 flex group grow w-full rounded-[20rem]" onClick={() => window.open('https://wa.me/message/6EBWZNLMHM6GE1', '_blank')}>
                <span className="text-white capitalize !text-2xl">Get a quote</span>
              </button>
              <a className="flex group grow justify-between items-center text-gray space-x-1" onClick={() => window.open('https://calendly.com/contact-9353/tomorrow_development', '_blank')}>
                <span className="text-white capitalize text-2xl">Schedule a call</span>
                <ArrowIcon className="relative left-0 group-active:left-1 transition-all" />
              </a>
            </div>
            <div className="w-full mt-8 mb-8 flex items-center space-x-3 w-full justify-center">
              <ShieldIcon />
              <p className="guarantee !text-2xl !font-normal">100% Money back Guarantee</p>
              <div className="relative group cursor-pointer">
                <InfoIcon />
                <div className="opacity-0 group-active:opacity-100 group-active:scale-100 delay-100 scale-50 absolute tooltip right-8 bottom-3 translate-y-1/2 min-w-[300px]  px-6 py-2 rounded-xl transition-all w-min">
                  We offer 100% money back if we do not meet the product deliverables.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-8 pt-9 pb-[70px]">
          <div className="pb-2.5 flex justify-between items-center">
            <h2 className="text-6xl">Our projects</h2>
          </div>
          <div className="flex flex-col gap-y-8 mt-10">
            <div className="card">
              <div>
                <img src={YieldfarmLogo} />
                <p>YieldFarm</p>
              </div>
              <p>A mobile app that allows to generate yield on your cash, through leveraging multiple DeFi stablecoins strategies</p>
            </div>
            {/*<div className="card">
                <div>
                  <p>Crystal X</p>
                </div>
                <p>Marketplace for crystals, leveraging AR to create realistic 3D renders of your crystals.</p>
  </div>*/}
            <div className="card">
              <div>
                <img className="object-contain" src={InscriptsLogo} />
                <p>Inscripts</p>
              </div>
              <p>A website tool that allows you to easily inscript bitcoin NFTs.</p>
            </div>
            <div className="card">
              <div>
                <img className="object-contain" src={ApexLogo} />
                <p className="!ml-[128px]">Apex Management</p>
              </div>
              <p>Inventory Management Software for Wholesale FBA</p>
            </div>
            <div className="card !pb-6 !w-full">
              <div className="!mb-0">
                <img src={ConfidentialLogo} style={{ objectFit: 'contain' }} />
                <div>
                  <p className="mb-2.5">Confidential Projects</p>
                  <p>We have worked on many more projects, with hundreds of thousands of daily active users. We can share more information regarding them in private.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainMobile
