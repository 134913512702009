import { FC } from 'react'

const PrivacyPolicy: FC = () => {
  return (
    <div className="[&_h2]:text-2xl [&_h2]:font-semibold [&_h3]:text-xl [&_h3]:font-medium [&_h4]:text-lg [&_h4]:font-medium [&_p]:text-base [&_p]:font-normal [&_*]:text-white p-6 [&_a:hover]:underline [&_a]:cursor-pointer [&_a]:text-blue-500">
      <h2>Privacy Policy</h2>
      <p>
        <br />
      </p>
      <h4>Effective Date: 19/9/2023</h4>
      <p>
        <br />
      </p>
      <h3>1. Introduction</h3>
      <p>
        <br />
      </p>
      <p>
        Welcome to Tomorrow Development (&quot;we,&quot; &quot;our,&quot; or &quot;us&quot;). We value your privacy and are committed to protecting your personal information. This Privacy Policy
        outlines how we collect, use, disclose, and safeguard the personal data we gather from visitors and users (&quot;you&quot; or &quot;your&quot;) of our website located at tomorrow.biz (the
        &quot;Website&quot;).
      </p>
      <p>
        <br />
      </p>
      <h3>2. Information We Collect</h3>
      <p>
        <br />
      </p>
      <p>We may collect the following personal information when you use our Website:</p>
      <p>
        <br />
      </p>
      <p>First Name</p>
      <p>Last Name</p>
      <p>Email Address</p>
      <p>Phone Number</p>
      <p>
        <br />
      </p>
      <h3>3. How We Use Your Information</h3>
      <p>
        <br />
      </p>
      <h4>We use the collected information for the following purposes:</h4>
      <p>
        <br />
      </p>
      <p>To provide you with information, products, or services you request from us.</p>
      <p>To improve our Website and services.</p>
      <p>To communicate with you, including responding to your inquiries or requests.</p>
      <p>To send you marketing and promotional materials (if you have opted in to receive such communications).</p>
      <p>To comply with legal obligations.</p>
      <p>4. Sharing Your Information</p>
      <p>
        <br />
      </p>
      <h4>We may share your personal information with:</h4>
      <p>
        <br />
      </p>
      <p>Our affiliates, partners, and third-party service providers who assist us in operating our Website or delivering our services.</p>
      <p>Law enforcement agencies, regulators, or other government bodies as required by law.</p>
      <p>5. Data Security</p>
      <p>
        <br />
      </p>
      <p>
        We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no data transmission over the internet
        or electronic storage system is entirely secure, so we cannot guarantee the security of your information.
      </p>
      <p>
        <br />
      </p>
      <h3>6. Cookies and Similar Technologies</h3>
      <p>
        <br />
      </p>
      <p>
        We may use cookies and similar technologies to enhance your experience on our Website. You can adjust your browser settings to refuse cookies, but doing so may limit your access to certain
        features on the Website.
      </p>
      <p>
        <br />
      </p>
      <h3>7. Your Rights</h3>
      <p>
        <br />
      </p>
      <p>
        You have certain rights regarding your personal information, including the right to access, correct, or delete your data. To exercise these rights or if you have questions or concerns about
        our data practices, please contact us at{' '}
        <a data-fr-linked="true" href="mailto:contact@tomorrow.biz">
          contact@tomorrow.biz
        </a>
      </p>
      <p>
        <br />
      </p>
      <h3>8. Changes to this Privacy Policy</h3>
      <p>
        <br />
      </p>
      <p>
        We may update this Privacy Policy from time to time to reflect changes in our data practices or legal requirements. Any updates will be posted on this page, and the effective date will be
        revised accordingly.
      </p>
      <p>
        <br />
      </p>
      <h3>9. Contact Us</h3>
      <p>
        <br />
      </p>
      <p>If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>
      <p>
        <br />
      </p>
      <p>Tomorrow</p>
      <p>Dolnohorska 74</p>
      <p>949 01 Nitra</p>
      <p>
        Email:{' '}
        <a data-fr-linked="true" href="mailto:contact@tomorrow.biz">
          contact@tomorrow.biz
        </a>
      </p>
    </div>
  )
}

export default PrivacyPolicy
