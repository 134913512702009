import { FC } from "react";

interface props {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
    size: number;
    z: number;
    translationX: number;
    translationY: number;
    rotation: number; 
    brightness?: number;
    blur?: number;
    dark?: boolean;
}

const Occlusion: FC<props> = ({ top, left, right, bottom, size, z, translationX, translationY, rotation, dark }) => {
    const tra = `translate3d(${translationX / (z * Math.abs(z))}px, ${translationY / (z * Math.abs(z))}px, ${z}px) rotate(${rotation}deg)`;
    const t = top ? top : document.body.clientHeight - (bottom || 0) - size
    return (
        <div style={{ width: size, height: size, backgroundColor: dark ? "rgba(148, 45, 194, 0.4)" : "rgba(159, 104, 184, 0.4)", borderRadius: size / 2,  transform: tra, position: "absolute", top: t, left, right, filter: "blur(162px)", zIndex: Math.floor(z) * 100 }} />
    );
};

export default Occlusion;