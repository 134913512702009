import { FC, useLayoutEffect, useRef, useState } from 'react'
import { default as ShardSvg } from '../../assets/shard.svg'

interface props {
  top?: number
  left?: number
  right?: number
  bottom?: number
  size: number
  z: number
  translationX: number
  translationY: number
  rotation: number
  brightness?: number
  blur?: number
  dark?: boolean
}

const Shard: FC<props> = ({ top, left, right, bottom, size, z, translationX, translationY, rotation, blur = 0, brightness = 100, dark }) => {
  const sq = Math.abs(z) * z
  const ref = useRef<HTMLImageElement>(null)
  const [scale, setScale] = useState((ref.current?.parentElement?.clientHeight || window.innerHeight) / 656)

  useLayoutEffect(() => {
    setScale((ref.current?.parentElement?.clientHeight || window.innerHeight) / 656)
  }, [])

  const t = top ? top * scale : document.body.clientHeight - (bottom || 0) * scale - size * scale
  const tra = `translate3d(${translationX * scale / sq}px, ${translationY * scale / sq}px, ${z}px) rotate(${rotation + translationY / (sq * 5)}deg)`
  const filter = `brightness(${Math.min(Math.max(brightness + sq * 0.005, 0), 100)}%) blur(${blur}px)`

  return <img ref={ref} src={ShardSvg} style={{ width: size * scale, height: size * scale, transform: tra, position: 'absolute', top: t, left: left ? left * scale : undefined, right: right ? right * scale : undefined, mixBlendMode: dark ? 'hard-light' : "normal", filter, zIndex: Math.floor(z) * 100 }} />
}

export default Shard
