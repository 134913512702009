import './config/firebase';
import { Outlet, Route, Routes } from 'react-router';
import MainPage from './pages/Main';
import SubscriptionPage from './pages/Subscription';
import { useEffect } from 'react';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  useEffect(() => {
    const isMobile = window.matchMedia("not screen and (hover)").matches
    if (!isMobile) return
    const styleSheets = Array.from(document.styleSheets)
    styleSheets.forEach((styleSheet) => {
      if (styleSheet.ownerNode?.nodeName === "STYLE") {
        const rules = Array.from(styleSheet.cssRules)
        rules.forEach((rule) => {
          if (rule instanceof CSSStyleRule) {
            if (rule.selectorText.includes(":hover")) {
              rule.selectorText = rule.selectorText.replace(":hover", ":active")
            }
          }
        })
      }
    })
  }, [])
  
  return (
    <div className="h-full overflow-y-scroll scroll-smooth">
      <Routes>
        <Route index Component={SubscriptionPage} />
        <Route path='home' Component={MainPage} />
        <Route path='legal' element={<Outlet />}>
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
