import { FirebaseOptions, initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyDha6dbWne1VdH12CSaIaNHYdriTSntknM",
  authDomain: "pitcher-15bac.firebaseapp.com",
  projectId: "pitcher-15bac",
  storageBucket: "pitcher-15bac.appspot.com",
  messagingSenderId: "53048487137",
  appId: "1:53048487137:web:753ebea84177e62e0d5ba0",
  measurementId: "G-K0ZNM3Q1XM"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

const firebase = { app, analytics }

export default firebase
