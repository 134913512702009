import { FC } from "react";
import MainDesktop from "./MainDesktop";
import MainMobile from "./MainMobile";

const MainPage: FC = () => {
    return (
        <>
            <MainDesktop />
            <MainMobile />
        </>
    )
}

export default MainPage