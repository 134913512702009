import { FC } from 'react'
import { ApexLogo, ArrowIcon, InscriptsLogo, CloseIcon, ConfidentialLogo, Logo, YieldfarmLogo, ShieldIcon, InfoIcon } from '../../assets'
import ShardBackground from '../../components/ShardBackground'

const MainDesktop: FC = () => {
  return (
    <div className="flex mobile:hidden h-full">
      <div
        className="w-full grow h-full overflow-y-hidden flex flex-col pl-[70px] pr-[70px] notmd:px-8 border-r border-r-[rgba(255,255,255,0.1)]"
        style={{ maxWidth: 'calc(max(43vw, min(658px, 70vw)))' }}
      >
        <img src={Logo} className="h-[48px] w-max mt-[27px]" />
        <div className="flex flex-col grow items-start justify-center">
          <h1 className="text-white text-left">We are a full-cycle development agency</h1>
          <p className="text-white description !mt-10">Our unique combination of location, experience and skills allows us to offer you with the best services on the market.</p>
          <p className="text-white description !mt-5 text-[18px] !font-medium">Don’t believe it? Find out for yourself right now!</p>
          <div className="flex justify-between space-x-8 !mt-[54px] md:items-center notmd:flex-col notmd:space-x-0 notmd:px-5 notmd:space-y-5 notmd:w-full">
            <button className="button-purple flex group grow" onClick={() => window.open('https://wa.me/message/6EBWZNLMHM6GE1', '_blank')}>
              <span className="text-white capitalize">Get a quote</span>
            </button>
            <button className="flex group grow justify-between space-x-3" onClick={() => window.open('https://calendly.com/contact-9353/tomorrow_development', '_blank')}>
              <span className="text-white capitalize">Schedule a call</span>
              <ArrowIcon className="relative left-0 group-hover:left-1 transition-all" />
            </button>
          </div>
        </div>
        <div className="h-9 notmd:h-8 mb-[60px] flex items-center space-x-3">
          <ShieldIcon />
          <p className="guarantee !font-normal">
            100% Money back Guarantee
          </p>
          <div className="relative group cursor-pointer">
            <InfoIcon />
            <div className="opacity-0 group-hover:opacity-100 group-hover:scale-100 delay-100 scale-50 absolute tooltip right-8 bottom-3 translate-y-1/2 min-w-[300px]  px-6 py-2 rounded-xl transition-all w-min">
              We offer 100% money back if we do not meet the product deliverables.
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col grow overflow-y-hidden relative" style={{ scrollBehavior: 'smooth' }}>
        <ShardBackground>
          <div className="min-h-full pb-[70px]">
            <div
              className="border-b border-b-px border-b-[rgba(255,255,255,0.1)] backdrop-blur-3xl backdrop-brightness-0 pl-8 pr-[130px] pb-2.5 flex justify-between items-center sticky top-[0px] pt-[70px] z-[1]"
              onClick={(e) => {
                const temp = document.getElementById("our-projects")
                if (temp) temp.style.opacity = "1";
                e.currentTarget.parentElement?.parentElement?.classList.remove('!top-0', 'opacity-100')
                e.currentTarget.parentElement?.parentElement?.scroll({ top: 0, behavior: 'smooth' })
              }}
            >
              <h2>Our projects</h2>
              <CloseIcon className="hover:scale-[0.95] transition-all cursor-pointer" />
            </div>
            <div className="flex flex-row flex-wrap gap-x-[55px] gap-y-8 mt-10 pl-8 pr-[130px]">
              <div className="card">
                <div>
                  <img src={YieldfarmLogo} />
                  <p>YieldFarm</p>
                </div>
                <p>A mobile app that allows to generate yield on your cash, through leveraging multiple DeFi stablecoins strategies</p>
              </div>
              {/*<div className="card">
                <div>
                  <p>Crystal X</p>
                </div>
                <p>Marketplace for crystals, leveraging AR to create realistic 3D renders of your crystals.</p>
            </div>*/}
              <div className="card">
                <div>
                  <img className="object-contain" src={InscriptsLogo} />
                  <p>Inscripts</p>
                </div>
                <p>A website tool that allows you to easily inscript bitcoin NFTs.</p>
              </div>
              <div className="card">
                <div>
                  <img className="object-contain" src={ApexLogo} />
                  <p className="!ml-[128px]">Apex Management</p>
                </div>
                <p>Inventory Management Software for Wholesale FBA</p>
              </div>
              <div className="card !pb-6 !w-full">
                <div className="!mb-0">
                  <img src={ConfidentialLogo} style={{ objectFit: 'contain' }} />
                  <div>
                    <p className="mb-2.5">Confidential Projects</p>
                    <p>We have worked on many more projects, with hundreds of thousands of daily active users. We can share more information regarding them in private.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ShardBackground>
      </div>
    </div>
  )
}

export default MainDesktop
