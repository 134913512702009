import { FC } from 'react'
import { ArrowMoreIcon } from '../../assets'

interface props {
  question: string
  answer: string
}

const FAQQuestion: FC<props> = ({ question, answer }) => {
  return (
    <div className="relative faq overflow-hidden h-full w-full">
      <div onClick={(e) => {
            const elements = e.currentTarget.parentElement?.parentElement?.getElementsByClassName('faq')
            if (!elements) return
            for (let i = 0; i < elements?.length; i++) {
                if (elements[i] === e.currentTarget.parentElement) continue
                elements[i].classList.remove('open')
            }
            e.currentTarget.parentElement?.classList.toggle('open')
        }} className="flex pt-6 mobile:pt-12 items-center justify-between cursor-pointer font-jakarta text-xl mobile:text-4xl font-bold">
        <span>{question}</span>
        <ArrowMoreIcon />
      </div>
      <div className="relative mobile:text-3xl">
        <div className="py-6 mobile:py-12 absolute" dangerouslySetInnerHTML={{__html: answer}}/>
        <div className="py-6 mobile:py-12 text-transparent" dangerouslySetInnerHTML={{__html: answer}}/>
      </div>
    </div>
  )
}

export default FAQQuestion
